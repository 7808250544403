/* Importa il theming di Angular Material usando @use */
@use '@angular/material' as mat;

/* Funzioni di base di Material */
@include mat.core();  

/* Palette Tema Chiaro */                
$light-primary: mat.m2-define-palette(mat.$m2-indigo-palette );
$light-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$light-warn: mat.m2-define-palette(mat.$m2-red-palette);
$light-theme: mat.m2-define-light-theme((
  color: (
    primary: $light-primary,
    accent: $light-accent,
    warn: $light-warn,
  )
));

/* Palette Tema Scuro */
$dark-primary: mat.m2-define-palette(mat.$m2-deep-purple-palette );
$dark-accent: mat.m2-define-palette(mat.$m2-amber-palette, A200, A100, A400);
$dark-warn: mat.m2-define-palette(mat.$m2-red-palette);
$dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $dark-primary,
    accent: $dark-accent,
    warn: $dark-warn,
  )
));

/* Palette Tema Personalizzato 1 */
// $custom1-primary: mat.m2-define-palette(mat.$m2-orange-palette);
// $custom1-accent: mat.m2-define-palette(mat.$m2-light-green-palette, A200, A100, A400);
// $custom1-warn: mat.m2-define-palette(mat.$m2-red-palette);
// $custom1-theme: mat.m2-define-light-theme((
//   color: (
//     primary: $custom1-primary,
//     accent: $custom1-accent,
//     warn: $custom1-warn,
//   )
// ));

// /* Palette Tema Personalizzato 2 */
// $custom2-primary: mat.m2-define-palette(mat.$m2-cyan-palette);
// $custom2-accent: mat.m2-define-palette(mat.$m2-yellow-palette, A200, A100, A400);
// $custom2-warn: mat.m2-define-palette(mat.$m2-orange-palette);
// $custom2-theme: mat.m2-define-light-theme((
//   color: (
//     primary: $custom2-primary,
//     accent: $custom2-accent,
//     warn: $custom2-warn,
//   )
// ));

// ********* APPLICO I COLORI  ***********************

  :root {
    // calendar
    --fc-page-bg-color: black;
    --fc-page-text-color: white;
    --fc-event-border-color: black;
    --fc-list-event-dot-width: 10px;
    --fc-list-event-hover-bg-color: transparent;

    --body-background-color: #ffffff;
    --on-low-gray-color: #dddbdb;
 

     /* Angular Material */ 
    --primary-color: #{mat.m2-get-color-from-palette($light-primary, 500)};
    --secondary-color: #{mat.m2-get-color-from-palette($light-primary, 400)};
    --accent-color: #{mat.m2-get-color-from-palette($light-accent, A200)};
    --warn-color: #{mat.m2-get-color-from-palette($light-warn, 500)};
    --background-color: #fafafa; /* Usa un colore di sfondo fisso */
    --surface-color: white; /* Usa un colore di superficie fisso */
    --on-primary-color: #{mat.m2-get-contrast-color-from-palette($light-primary, 500)};
    --on-accent-color: #{mat.m2-get-contrast-color-from-palette($light-accent, A200)};
    --on-warn-color: #{mat.m2-get-contrast-color-from-palette($light-warn, 400)};
    --on-background-color: black; /* Usa un colore di contrasto fisso */
    --on-surface-color: black; /* Usa un colore di contrasto fisso */
    --primary-hover-color: #{mat.m2-get-color-from-palette($light-primary, 500)};
    --accent-hover-color: #{mat.m2-get-color-from-palette($light-accent, A400)};
    --warn-hover-color: #{mat.m2-get-color-from-palette($light-warn, 700)};

    /* PrimeNG */
    --primeng-primary-color: #{mat.m2-get-color-from-palette($light-primary, 500)};
    --primeng-accent-color: #{mat.m2-get-color-from-palette($light-accent, A200)};
    --primeng-warn-color: #{mat.m2-get-color-from-palette($light-warn, 500)};
    --primeng-background-color: #fafafa; /* Usa un colore di sfondo fisso */
    --primeng-surface-color: white; /* Usa un colore di superficie fisso */
    --primeng-on-primary-color: #{mat.m2-get-contrast-color-from-palette($light-primary, 500)};
    --primeng-on-accent-color: #{mat.m2-get-contrast-color-from-palette($light-accent, A200)};
    --primeng-on-warn-color: #{mat.m2-get-contrast-color-from-palette($light-warn, 500)};
    --primeng-on-background-color: black; /* Usa un colore di contrasto fisso */
    --primeng-on-surface-color: black; /* Usa un colore di contrasto fisso */
    --primeng-primary-hover-color: var(--primary-hover-color); 
    --primeng-accent-hover-color: var(--accent-hover-color);
    --primeng-warn-hover-color: var(--warn-hover-color);
  }

 
  
  .light-theme {

    --body-background-color: #ffffff;
    --on-body-background-color: #000000;
    --on-body-background-color-gray: #6e6d6d;
    --on-low-gray-color: #dddbdb;

    /* Angular Material */
    --primary-color: #{mat.m2-get-color-from-palette($light-primary, 500)};
    --secondary-color: #{mat.m2-get-color-from-palette($light-primary, 300)}; 
    --on-secondary-color: #fafafa;
    --secondary-color-blur: #{mat.m2-get-color-from-palette($light-primary, 200)};
    --on-secondary-color-blur: #fafafa;
    --accent-color: #{mat.m2-get-color-from-palette($light-accent, A200)};
    --warn-color: #{mat.m2-get-color-from-palette($light-warn, 500)};
    --background-color: #fafafa; /* Usa un colore di sfondo fisso */
    --surface-color: white; /* Usa un colore di superficie fisso */
    --on-primary-color: #{mat.m2-get-contrast-color-from-palette($light-primary, 500)};
    --on-accent-color: #{mat.m2-get-contrast-color-from-palette($light-accent, A200)};
    --on-warn-color: #{mat.m2-get-contrast-color-from-palette($light-warn, 500)};
    --on-background-color: black; /* Usa un colore di contrasto fisso */
    --on-surface-color: black; /* Usa un colore di contrasto fisso */
    --primary-hover-color: #{mat.m2-get-color-from-palette($light-primary, 200)}; 
    --on-primary-hover-color: black;
    --accent-hover-color: #{mat.m2-get-color-from-palette($light-accent, A400)};
    --warn-hover-color: #{mat.m2-get-color-from-palette($light-warn, 700)};

      /* PrimeNG */
    --primeng-primary-color: #{mat.m2-get-color-from-palette($light-primary, 500)};
    --primeng-accent-color: #{mat.m2-get-color-from-palette($light-accent, A200)};
    --primeng-warn-color: #{mat.m2-get-color-from-palette($light-warn, 500)};
    --primeng-background-color: #fafafa; /* Usa un colore di sfondo fisso */
    --primeng-surface-color: #e4e0e0; /* Usa un colore di superficie fisso */
    --primeng-on-primary-color: #{mat.m2-get-contrast-color-from-palette($light-primary, 500)};
    --primeng-on-accent-color: #{mat.m2-get-contrast-color-from-palette($light-accent, A200)};
    --primeng-on-warn-color: #{mat.m2-get-contrast-color-from-palette($light-warn, 500)};
    --primeng-on-background-color: black; /* Usa un colore di contrasto fisso */
    --primeng-on-surface-color: black; /* Usa un colore di contrasto fisso */
    --primeng-primary-hover-color: var(--primary-hover-color);
    --primeng-accent-hover-color: var(--accent-hover-color);
    --primeng-warn-hover-color: var(--warn-hover-color);

    @include mat.all-component-colors($light-theme);
  }
  
  .dark-theme {

    --body-background-color: #000000;
    --on-body-background-color: #ffffff;
    --on-body-background-color-gray: #a3a3a3;
    --on-low-gray-color: #212121;

    /* Angular Material */
    --primary-color: #{mat.m2-get-color-from-palette($dark-primary, 500)};
    --secondary-color: #{mat.m2-get-color-from-palette($dark-primary, 200)};
    --on-secondary-color: #121212;
    --secondary-color-blur: #{mat.m2-get-color-from-palette($dark-primary, 200)};
    --on-secondary-color-blur: #000000;
    --accent-color: #{mat.m2-get-color-from-palette($dark-accent, A200)};
    --warn-color: #{mat.m2-get-color-from-palette($dark-warn, 500)};
    --background-color: #121212; /* Usa un colore di sfondo fisso */
    --surface-color: #1e1e1e; /* Usa un colore di superficie fisso */
    --on-primary-color: #{mat.m2-get-contrast-color-from-palette($dark-primary, 500)};
    --on-accent-color: #{mat.m2-get-contrast-color-from-palette($dark-accent, A200)};
    --on-warn-color: #{mat.m2-get-contrast-color-from-palette($dark-warn, 500)};
    --on-background-color: white; /* Usa un colore di contrasto fisso */
    --on-surface-color: white; /* Usa un colore di contrasto fisso */
    --primary-hover-color: #{mat.m2-get-color-from-palette($dark-primary,200)};
    --on-primary-hover-color: #121212;
    --accent-hover-color: #{mat.m2-get-color-from-palette($dark-accent, A400)};
    --warn-hover-color: #{mat.m2-get-color-from-palette($dark-warn, 700)};

      /* PrimeNG */
    --primeng-primary-color: #{mat.m2-get-color-from-palette($dark-primary, 500)}; 
    --primeng-accent-color: #{mat.m2-get-color-from-palette($dark-accent, A200)};
    --primeng-warn-color: #{mat.m2-get-color-from-palette($dark-warn, 500)};
    --primeng-background-color: #121212; /* Usa un colore di sfondo fisso */
    --primeng-surface-color: #cdcbcb; /* Usa un colore di superficie fisso */
    --primeng-on-primary-color: #{mat.m2-get-contrast-color-from-palette($dark-primary, 500)};
    --primeng-on-accent-color: #{mat.m2-get-contrast-color-from-palette($dark-accent, A200)};
    --primeng-on-warn-color: #{mat.m2-get-contrast-color-from-palette($dark-warn, 500)};
    --primeng-on-background-color: white; /* Usa un colore di contrasto fisso */
    --primeng-on-surface-color: white; /* Usa un colore di contrasto fisso */
    --primeng-primary-hover-color: var(--primary-hover-color);
    --primeng-accent-hover-color: var(--accent-hover-color);
    --primeng-warn-hover-color: var(--warn-hover-color);

    @include mat.all-component-colors($dark-theme);
  }
  

  //*TODO °°°°° HOW TO HANDLE MULTI THEMEs :) °°°°°°°°°°°°°°°
    [data-on-theme="on-theme"] {
      --body-background-color: #ffa60053; 
    }

    [data-on-theme="dark-theme"] {
      // --body-background-color: #021f02; 
    }

    [data-on-theme="light-theme"] {
      // --body-background-color: #f5baba ; 
    }

  //   [data-pf-theme="primeone-dark"] {
  //     --landing-body-bg: #00031a;
  //     --landing-text-color: #c9c9c9;
  //     --landing-border: 1px solid rgba(255, 255, 255, 0.1);
  //     --landing-section-border: linear-gradient(
  //         90deg,
  //         rgba(0, 67, 238, 0.55) 0%,
  //         rgba(0, 102, 255, 0) 60%,
  //         rgba(0, 255, 240, 0.45) 100%
  //     );
  //     --landing-footer-border: linear-gradient(
  //         270deg,
  //         rgba(255, 255, 255, 0.2) -0.2%,
  //         rgba(0, 0, 0, 0.2) 49.87%,
  //         rgba(255, 255, 255, 0.2) 99.93%
  //     );
  //     --landing-menu-item-text-color: rgba(255, 255, 255, 0.4);
  //     --landing-menu-item-hover-text-color: #04b9d7;
  
  //     --blocks-selection-bg: rgba(7, 0, 33, 0.75);
  //     --blocks-selection-shadow: 0px 0px 0px 1px #0d276b;
  //     --blocks-selection-item-bg: #061a49;
  //     --blocks-selection-item-shadow: 0px 0px 0px 1px #0a2a76, 0px 2px 4px rgba(0, 0, 0, 0.07),
  //         0px 1px 1.5px rgba(0, 0, 0, 0.05);
  //     --blocks-selection-item-text-color: #ffffff;
  //     --blocks-overlay-triangle-bg: #0d276b;
  //     --primary-button-bg: #0078e3;
  //     --primary-button-shadow: 0px 0px 0px 1px #4dabff, 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1.5px rgba(0, 0, 0, 0.05);
  //     --primary-button-hover-bg: linear-gradient(112.31deg, #00e08f 19.49%, #00a3ff 98.51%);
  //     --primary-button-text-color: #ffffff;
  //     --primary-button-hover-text-color: #00031a;
  
  //     --secondary-button-bg: #061a49;
  //     --secondaty-button-shadow: 0px 0px 0px 1px #0a2a76, 0px 2px 4px rgba(0, 0, 0, 0.07),
  //         0px 1px 1.5px rgba(0, 0, 0, 0.05);
  //     --secondary-button-hover-bg: #0a2a76;
  //     --secondaty-button-text-color: #ffffff;
  //     --secondary-button-hover-text-color: #ffffff;
  
  //     //hero
  //     --hero-bg-light-1: linear-gradient(0deg, #000000 1.39%, #ffffff 100%);
  //     --hero-bg-light-2: linear-gradient(0deg, #ffffff 0%, #00ff94 100%);
  //     --hero-bg-light-3: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
  //     --hero-bg-light-4: linear-gradient(180deg, #dbdbdb 0%, #000aff 100%);
  //     --hero-bg-light-mix-mode: screen;
  
  //     //hero-box
  //     --hero-box-bg: linear-gradient(116.22deg, #012278 33.5%, rgba(1, 25, 85, 0.58) 99.16%);
  //     --hero-box-shadow: 0px 0px 28px 14px #000424;
  //     --hero-box-border: 2px solid #0078e3;
  //     --hero-box-text-color: #ffffff;
  //     --hero-box-classes-bg: rgba(0, 120, 227, 0.4);
  //     --hero-box-classes-purple-bg: rgba(128, 0, 255, 0.4);
  //     --hero-box-pink-bg: rgba(227, 0, 204, 0.4);
  
  //     //docsearch
  //     --docsearch-bg: rgba(7, 0, 33, 0.75);
  //     --docsearch-shadow: 0px 0px 0px 1px #00031a, 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1.5px rgba(0, 0, 0, 0.05);
  //     --docsearch-color: #808080;
  
  //     //download-box
  //     --download-box-bg: #070021;
  //     --download-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.05);
  //     --download-box-border: 1px solid #0d276b;
  
  //     //cards
  //     --cards-bg: radial-gradient(93.88% 63.6% at 50% 0%, rgba(2, 225, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
  //         rgba(1, 4, 27, 0.3);
  //     --cards-bg-color: #00031a;
  //     --cards-border: 1px solid rgba(255, 255, 255, 0.1);
  //     --cards-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
  //     --cards-color-text-color: rgba(255, 255, 255, 0.7);
  //     --color-cards-animated-bg: rgba(1, 4, 36, 0.2);
  //     --layout-card-bg: rgba(255, 255, 255, 0.1);
  //     --layout-card-border: 1px solid rgba(255, 255, 255, 0.15);
  //     --card-slider-text-color: rgba(255, 255, 255, 0.7);
  //     --slider-bg: transparent;
  //     --slider-handle-bg: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0.01%, rgba(0, 0, 0, 0.12) 100%),
  //         linear-gradient(0deg, #061a49, #061a49), #002342;
  //     --slider-handle-border: none;
  //     --slider-div-bg: transparent;
  //     --slider-handle-icon-color: #6a758f;
  //     //animations
  //     --animations-text-backdrop: linear-gradient(180deg, rgba(1, 4, 27, 0) 0%, #00031a 29.97%);
  //     --animation-card-bg: radial-gradient(
  //             79.09% 79.09% at 50% 98.36%,
  //             rgba(255, 255, 255, 0.14) 0%,
  //             rgba(255, 255, 255, 0) 100%
  //         ),
  //         rgba(255, 255, 255, 0.1);
  //     --animation-card-border: 1px solid rgba(255, 255, 255, 0.2);
  
  //     //style-cards
  //     --style-cards-fancy-bg: radial-gradient(93.88% 63.6% at 50% 0%, rgba(2, 225, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
  //         #01041b;
  //     --style-cards-bg: linear-gradient(0deg, rgba(1, 4, 27, 0.4), rgba(1, 4, 27, 0.4)),
  //         radial-gradient(93.88% 63.6% at 50% 0%, rgba(2, 225, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
  //     --style-cards-section-bg: rgba(255, 255, 255, 0.1);
  //     --style-cards-header-text-color: rgba(255, 255, 255, 0.6);
  
  //     //code
  //     --code-background: #1f2338 !important;
  //     --code-border: 1px solid rgba(255, 255, 255, 0.1) !important;
  //     --code-divs-color: #cc00ff;
  //     --code-text-color: rgba(255, 255, 255, 0.6);
  //     --code-class-text-color: rgba(0, 194, 255, 1);
  
  //     //responsive
  //     --responsive-breaks-bg: rgba(1, 4, 27, 0.3);
  //     --responsive-breaks-border: 1px solid rgba(255, 255, 255, 0.1);
  //     --responsive-breaks-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3);
  //     --responsive-active-bg: radial-gradient(
  //             79.09% 79.09% at 50% 98.36%,
  //             rgb(0 0 0 / 14%) 0%,
  //             rgba(255, 255, 255, 0) 100%
  //         ),
  //         rgba(255, 255, 255, 0.1);
  //     --responsive-active-bg-side-1: radial-gradient(
  //             79.09% 79.09% at 50% 98.36%,
  //             rgb(0 0 0 / 14%) 0%,
  //             rgba(255, 255, 255, 0) 100%
  //         ),
  //         rgba(255, 255, 255, 0.1);
  //     --responsive-active-bg-side-2: radial-gradient(
  //             79.09% 79.09% at 50% 98.36%,
  //             rgb(0 0 0 / 14%) 0%,
  //             rgba(255, 255, 255, 0) 100%
  //         ),
  //         rgba(255, 255, 255, 0.1);
  //     --responsive-resize-handle-bg: rgba(255, 255, 255, 0.1);
  //     --responsive-resize-handle-border: 1px solid rgba(255, 255, 255, 0.1);
  //     --responsive-resize-handle-hover-bg: rgba(255, 255, 255, 0.2);
  //     --responsive-resize-handle-icon-color: rgba(255, 255, 255, 0.4);
  //     --responsive-iframe-shadow: none;
  
  //     //blocks
  //     --blocks-backdrop: radial-gradient(50% 50% at 50% 50%, #00031a 0%, rgba(0, 3, 26, 0.5) 50%, #00031a 100%);
  // }

//   [data-pf-theme="primeone-light"] {
//     --landing-body-bg: #fff;
//     --landing-text-color: #333333;
//     --landing-border: 1px solid rgba(0, 0, 0, 0.1);
//     --landing-section-border: linear-gradient(
//         90deg,
//         rgba(0, 67, 238, 0.55) 0%,
//         rgba(0, 45, 160, 0) 60%,
//         rgba(0, 255, 240, 0.45) 100%
//     );

//     --landing-footer-border: linear-gradient(
//         270deg,
//         rgba(0, 0, 0, 0.2) -0.2%,
//         rgba(255, 255, 255, 0.2) 49.87%,
//         rgba(0, 0, 0, 0.2) 99.93%
//     );
//     --landing-menu-item-text-color: #505050;
//     --landing-menu-item-hover-text-color: #04b9d7;

//     --blocks-selection-bg: rgba(255, 255, 255, 0.75);
//     --blocks-selection-shadow: 0px 0px 0px 1px #e0e0e0, 0px 2px 4px rgba(0, 0, 0, 0.07),
//         0px 1px 1.5px rgba(0, 0, 0, 0.05);
//     --blocks-selection-item-bg: #ffffff;
//     --blocks-selection-item-shadow: 0px 0px 0px 1px #e0e0e0, 0px 2px 4px rgba(0, 0, 0, 0.07),
//         0px 1px 1.5px rgba(0, 0, 0, 0.05);
//     --blocks-selection-item-text-color: #8a8a8a;
//     --blocks-overlay-triangle-bg: #e0e0e0;

//     --primary-button-bg: #0078e3;
//     --primary-button-shadow: 0px 0px 0px 1px #4dabff, 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1.5px rgba(0, 0, 0, 0.05);
//     --primary-button-hover-bg: linear-gradient(112.31deg, #00e08f 19.49%, #00a3ff 98.51%);
//     --primary-button-text-color: #ffffff;
//     --primary-button-hover-text-color: #00031a;

//     --secondary-button-bg: #ffffff;
//     --secondaty-button-shadow: 0px 0px 0px 1px #e0e0e0, 0px 2px 4px rgba(0, 0, 0, 0.07),
//         0px 1px 1.5px rgba(0, 0, 0, 0.05);
//     --secondary-button-hover-bg: #ffffff;
//     --secondaty-button-text-color: #8a8a8a;
//     --secondary-button-hover-text-color: #00a3ff;

//     //hero
//     --hero-bg-light-1: linear-gradient(0deg, #ffffff 1.39%, #4feaff 100%);
//     --hero-bg-light-2: linear-gradient(0deg, #ffffff 0%, #00ff94 100%);
//     --hero-bg-light-3: linear-gradient(0deg, #ffffff 0%, #ffffff 100%);
//     --hero-bg-light-4: linear-gradient(0deg, #dbdbdb 0%, #000aff 100%);
//     --hero-bg-light-mix-mode: multiply;

//     //hero-box
//     --hero-box-bg: linear-gradient(116.22deg, #ffffff 33.5%, rgba(255, 255, 255, 0.58) 99.16%);
//     --hero-box-shadow: 0px 0px 28px 14px rgba(0, 4, 36, 0.1);
//     --hero-box-border: 2px solid rgba(212, 212, 212, 1);
//     --hero-box-text-color: #000000;
//     --hero-box-classes-bg: rgba(0, 120, 227, 0.4);
//     --hero-box-classes-purple-bg: rgba(128, 0, 255, 0.4);
//     --hero-box-pink-bg: rgba(227, 0, 204, 0.4);

//     //docsearch
//     --docsearch-bg: #ffffff;
//     --docsearch-shadow: 0px 0px 0px 1px #e0e0e0, 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1.5px rgba(0, 0, 0, 0.05);
//     --docsearch-color: #8f8f8f;

//     //download-box
//     --download-box-bg: #ffffff;
//     --download-box-shadow: 0px 0px 0px 1px #e0e0e0, 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 1px 1.5px rgba(0, 0, 0, 0.05);
//     --download-box-border: none;

//     //cards
//     --cards-bg: #fff;
//     --cards-bg-color: #fff;
//     --cards-border: 1px solid rgba(0, 0, 0, 0.1);
//     --cards-shadow: none;
//     --cards-color-text-color: rgba(0, 0, 0, 0.3);
//     --color-cards-animated-bg: rgba(118, 179, 197, 0.2);
//     --layout-card-bg: rgba(0, 0, 0, 0.01);
//     --layout-card-border: 1px solid rgba(0, 0, 0, 0.1);
//     --card-slider-text-color: rgba(0, 0, 0, 0.7);
//     --slider-bg: #f7f7f7;
//     --slider-handle-bg: #ffffff;
//     --slider-handle-border: 0px 1px 2px rgba(0, 0, 0, 0.04);
//     --slider-div-bg: #ffffff;
//     --slider-handle-icon-color: #ebebeb;

//     //animations
//     --animations-text-backdrop: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 29.97%);
//     --animation-card-bg: rgba(255, 255, 255, 0.4);
//     --animation-card-border: 1px solid rgba(0, 0, 0, 0.1);

//     //style-cards
//     --style-cards-fancy-bg: radial-gradient(93.88% 63.6% at 50% 0%, rgba(2, 225, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
//         #01041b;
//     --style-cards-bg: linear-gradient(0deg, rgba(1, 4, 27, 0.4), rgba(1, 4, 27, 0.4)),
//         radial-gradient(93.88% 63.6% at 50% 0%, rgba(2, 225, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);

//     --style-cards-section-bg: #ffffff;
//     --style-cards-header-text-color: #c9c9c9;
//     //code
//     --code-background: rgba(247, 247, 247, 0.9) !important;
//     --code-border: 1px solid rgba(0, 0, 0, 0.1) !important;
//     --code-divs-color: rgba(255, 102, 204, 1);
//     --code-text-color: rgba(0, 0, 0, 0.6);
//     --code-class-text-color: rgba(0, 111, 255, 1);

//     //responsive
//     --responsive-breaks-bg: gba(1, 4, 27, 0.3);
//     --responsive-breaks-border: 1px solid rgba(0, 0, 0, 0.1);
//     --responsive-breaks-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06);
//     --responsive-active-bg: linear-gradient(112.31deg, #00e08f 19.49%, #00a3ff 98.51%);
//     --responsive-active-bg-side-1: #00e08f;
//     --responsive-active-bg-side-2: #00a3ff;
//     --responsive-resize-handle-bg: rgba(0, 0, 0, 0.1);
//     --responsive-resize-handle-border: 1px solid rgba(0, 0, 0, 0.1);
//     --responsive-resize-handle-hover-bg: rgba(0, 0, 0, 0.2);
//     --responsive-resize-handle-icon-color: rgba(0, 0, 0, 0.4);
//     --responsive-iframe-shadow: 0px 2px 6px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.06),
//         0px 4px 10px rgba(0, 0, 0, 0.03);

//     //blocks
//     --blocks-backdrop: radial-gradient(
//         50% 50% at 50% 50%,
//         #ffffff 33.9%,
//         rgba(255, 255, 255, 0.5) 76.06%,
//         #ffffff 100%
//     );
// }