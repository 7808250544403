.c-fikloo {
    width: 448px;
    height: 87%;
    max-height: 800px;
}



/*  HEADER ICON    */
.c-kIbiaN { 
    background-color: transparent; 
    height: 60px!important;
    width: 60px!important;
}

/*  HEADER  */
.c-iCDrnV { 
    background-color:  #86078d; 
    height: 80px!important; 
}

/*  TITOLO    */
.c-kfyIxT {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: var(--fonts-default);
    font-size: 20px;
    font-weight:  700;
    line-height:  1.2;
    color: #ffffff;
}

/*  CHAT BODY    */
.c-Mmsau {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden scroll;
    padding-bottom: var(--space-3);
    /* background-color: rgba(0, 255, 81, 0.459); */
}

/*  CHAT FOOTER    */
.c-kbCYVp {
    background-color: #86078d;
    border-radius: 0;
     
}

/*  LOGO AZIENDDA    */
.c-kIbiaN-ihwEvnK-css {
    background-image: url(../img/bot-recharger.png); 
    background-size: 50%;
    height: 50px!important;
    width: 50px!important;
}

/* CARD */
.c-jUajGf {
    width: auto;
}


/* CHAT AI    */
.c-iTxaCK {
    /* display: flex;
    flex-direction: column;
    flex: inherit; */
    /*border: 1px solid red;*/
    width: 80%;
}

.vfrc-message--extension-Forms{
   /*  border: 2px solid green; */
    padding: 0;
}



/* CHAT USER    */
.c-PJLV-gJqBfM-from-user {
    color:  #ffffff;
    background-color: #86078d;
}


/*  LOUNCH BUTTON    */
.c-bQoszf {
    background-color: #86078d;
    color: #ffffff; 
}


.vfrc-timestamp{
    border: 1px solid red;
    width: 10px !important;
    visibility: hidden;
}

.vfrc-timestamp:hover{
    visibility: hidden;
}


/* ****************************************** */

  .extContainer{ 
    background-color: #86078d; 
    padding: 6px; 
    border-radius: 5px; 
    margin: 0;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  label {
    font-size: 1em;
    color: #fff;
    font-weight: 600;
    display: block;
  }

  select{
    width: 100%; 
    height: 2rem;
    border-radius: 5px;  
    /*background-color: #f0f0f0; *//* Colore di sfondo del menu */
    color: #000; /* Colore del testo */
  } 
  
  select option {
    background-color: #ffffff; /* Sfondo degli elementi non selezionati */
    color: #000; /* Testo degli elementi */
  }
  
  select option:checked,  
  select option:visited,  
  select option:hover, 
  select option:focus,
  select option:active {
    background-color: #86078d!important; /* Sfondo dell'elemento selezionato */
    color: #ffffff; /* Testo dell'elemento selezionato */
  } 


   

  input[type="text"], input[type="time"], input[type="email"], input[type="tel"] {
    width: 98%;
    height: 2rem;
    border: 1px solid gray;
    border-radius: 5px;  
    background: transparent; 
    margin: 0 0 2px 0;
  } 
  

  .invalid {
    border-color: red;
  }

  .submit {
    background: #24022691;
    border: none;
    color: white;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    cursor: pointer;
  }

  .submit:enabled {
    opacity: 1; /* Make the button fully opaque when it's enabled */
  }


 

