@use 'theme/theme';
 

html, body { height: 98vh; }
body { 
    margin: 0;
    padding: 0;  
}


// Material Design breakpoints are implemented in app.component.ts 
// Extra Small (xs): max-width: 599px
// Small (sm): min-width: 600px e max-width: 959px
// Medium (md): min-width: 960px e max-width: 1279px
// Large (lg): min-width: 1280px e max-width: 1919px
// Extra Large (xl): min-width: 1920px  



.testo-barrato {
  text-decoration: line-through;
}


//***** PRIMEng  PANEL MENU **************************

.p-panelmenu-panel { 
  background-color: transparent!important; 
  border-width: 0!important; 
} 

.p-panelmenu-header:not(.p-disabled) .p-panelmenu-header-content:hover {
  background-color: var(--primary-hover-color)!important;
  color: var(--on-primary-hover-color)!important; 
}
 

.p-panelmenu-item:not(.p-disabled) > .p-panelmenu-item-content:hover,
.p-panelmenu-item:not(.p-disabled) > .p-panelmenu-item-content:active,
.p-panelmenu-item:not(.p-disabled) > .p-panelmenu-item-content:focus-visible {
  background: var(--secondary-color)!important; 
  color: var(--on-secondary-color)!important;  
  outline: none!important;
  box-shadow: none!important;
  border-color: transparent!important;
}

.p-panelmenu-submenu-icon {
  color: white!important;
}

//***** PRIMEng  chip **************************

.p-chip {
  display: inline-flex;
  align-items: center;
  background: transparent!important;
  color: var(--p-chip-color);
  border-radius: var(--p-chip-border-radius);
  padding: var(--p-chip-padding-y) var(--p-chip-padding-x);
  gap: var(--p-chip-gap);
}


//***** PRIMEng  INPUTS **************************

.p-inputtext{
  border-radius: 1.5rem;
}

.p-inputtext:enabled:focus{
  box-shadow: none; 
}

//***** PRIMEng BUTTONS   **************************
  

  .p-button.p-button-primary{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--on-primary-color);
  }

  .p-button.p-button-secondary{
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    color: var(--on-secondary-color);
  }

  .p-button.p-button-primary{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .p-button.p-button-text{ 
      color: var(--on-body-background-color); 
  }

  .p-button.p-button-rounded{
      background: var(--primary-color);
      border: 1px solid var(--primary-color);
      color: var(--on-primary-color);
  } 

  .p-button.p-button-outlined{
      border-color: var(--primary-color);
      color: var(--primary-color);
      background-color: transparent;
      border-width: 3px;
  }  


  .p-button-label{
    // text-transform: uppercase; 
    &::first-letter{
      text-transform: capitalize; 
    } 
  }

  .p-button:focus{
    outline: none;
    box-shadow: none; 
  }

   
 

//***** PRIMEng TOAST   **************************
.p-toast{
  width: auto;
  min-width: 20rem;
  .p-toast-detail::first-letter{
    text-transform: capitalize; 
  }
}

.p-toast .p-toast-message.p-toast-message-success{
    border-width: 0 0 0 10px;
    background: #6eee707e;
    color: black; 
}

.p-toast .p-toast-message.p-toast-message-warn{ 
  border-width: 0 0 0 10px;
  background: #ca852a85;
  color: black; 
}

.p-toast .p-toast-message.p-toast-message-error{
  border-width: 0 0 0 10px;
  background: #ee6e6e7e;
  color: black;
}

.p-toast .p-toast-message.p-toast-message-info{
  border-width: 0 0 0 10px;
  background: #6e9eee7e;
  color: black;
}

 


//**** PRIMEng PAGINATOR **************************
.p-paginator{ 
    background-color: transparent; 
    border: 0px solid #ede8e8; 
}
 
.p-inputwrapper-focus,
.p-dropdown:focus,
.p-dropdown:focus-visible {
  outline: none;
  box-shadow: none;
  border-color: transparent;
}


//****  SCROLLBAR STYLES  ************************** 
// for Chrome and Safari
::-webkit-scrollbar {
    width: 8px; // for vertical scrollbars 
    height: 8px; // for horizontal scrollbars
  }
  ::-webkit-scrollbar-thumb {
    background: #293749; // bg bar
  }
   
  ::-webkit-scrollbar-thumb:rounded {
    border-radius: 4px; // opzional to round the corners
  }
   
  ::-webkit-scrollbar-track {
    background: #9e9b9b; // bg track
  }
   

  //**** MAT PAGINATOR **************************
  // mat-paginator{   
  //   width: 100%!important; 
  //   border-top-left-radius: 6px;
  //   border-top-right-radius: 6px;
  //   // background-color: transparent!important;
  //   background-color: var(--background-color);
  //   color: var(--on-background-color);
  //   margin: 0.3rem 0!important; 

  //   .mat-mdc-paginator-container{
  //     padding: 0!important;
  //   }
  // }  


   //*** TABLES *************

   td > span{
    font-size: .8rem;
   }


  //*** utilites classes ( as tailwind ) *************

 .first-letter-capitalize{
    &::first-letter{
      text-transform: capitalize;
    } 
  }

  .uppercase{
    text-transform: uppercase;
  }

  .lowercase{
    text-transform: lowercase;
  }

  .relative{
    position: relative;
  }

  .block{
    display: block;
  }
  .inline-flex{
    display: inline-flex;
  }
  .flex{
    display: flex;
  }
  .flex-column{
    flex-direction: column;
  }
  .flex-row{
    flex-direction: row;
  }
  
  .items-center{
    align-items: center;
  }
  .items-start{
    align-items: flex-start;
  }
  .items-end{
    align-items: flex-end;
  }
  
  .justify-start {
    justify-content: flex-start;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-content-around {
    justify-content: space-around;
  }
  .w-full{
    width: 100%;
  }
  .gap-1{
    gap: 0.5rem;
  }
  .gap-2{
    gap: 1rem;
  }
  .gap-3{
    gap: 1.5rem;
  }

  .text-xs {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
  }

  .text-sm {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.25rem;
  }

  .text-md { 
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
  }

  .text-lg {
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.75rem;
  }

  .text-xl{
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.75rem;
  }

  .text-2xl {
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 2rem;
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    margin-left: -1rem;
    margin-top: -1rem;
  }
  .col-12 {
    flex: 0 0 auto;
    padding: 1rem;
    width: 100%;
  }
  .col-6 {
    flex: 0 0 auto;
    padding: 1rem;
    width: 50%;
  }
  .card {
    background-color:  var(--primeng-surface-color);
    padding: 1rem;
    margin-bottom: 2rem; 
    border-radius: .8rem;
  }

  // HOW TO USE BREAKPOINTS
  .xs, .sm, .md {
    .col{
      flex: 0 0 auto;
      padding: 1rem;
      width: 100%; 
    } 
  } 
 
  .lg, .xl {
    .col{
      flex: 0 0 auto;
      padding: 1rem;
      width: 50%; 
    } 
  }  


